















































import { Component, Vue } from 'vue-property-decorator'
import newDaliog from './new-dialog.vue'
import oldDaliog from './old-dialog.vue'
import { CommonMudule } from '@/store/modules/common'
import Cookies from 'js-cookie'

@Component({
  name: 'user',
  components: {
    newDaliog,
    oldDaliog
  }
})
export default class extends Vue {
  userList =[{
    label: '新客户',
    value: 1,
    logo: '+'
  }, {
    label: '已有客户',
    value: 2,
    logo: '2'
  }]
  n:number = 2
  get getName() {
    const router = this.$route.path
    let name
    if (router === '/userSelect') {
      name = '用户'
    } else {
      name = CommonMudule.customerName
    }
    return name
  }
  get route() {
    const router = this.$route.path
    return router
  }
  public setItem(key: any, value: any) {
    window.sessionStorage.setItem(key, value)
  }
  public getItem(key: string) {
    return sessionStorage.getItem(key)
  }

  get logoUrl() {
    return CommonMudule.imgSource.logo[0].url
  }

  mounted() {
    const token = Cookies.get('nbToken')
    if (token) {
      this.setItem('token', token)
    }
  }
  changeList(index:number) {
    this.n = index// this指向app
    console.log(index)
    if (index === 0) {
      (this.$refs.newDialog as any).show()
    } else {
      (this.$refs.oldDaliog as any).showDialog()
    }
  }
}
