





















































import { Vue, Component } from 'vue-property-decorator'
import formItem from '@/components/formItem/index.vue'
import handleBtn from '@/components/handleBtn/index.vue'
import { OptArr } from '@/types/kyc'
import { custormSave } from '@/api/users'
import { userTable } from '@/types/user'
import { CommonMudule } from '@/store/modules/common'
import { PortMudule } from '@/store/modules/portfolio'

@Component({
  name: 'newDaliog',
  components: {
    formItem,
    handleBtn
  }
})
export default class extends Vue {
  newDialogVisible = false
  alone = null
  private isFirst: Boolean = true
   private creatModel:userTable = {
     customerName: '',
     age: '',
     gender: ''
   }
   private sexItem: OptArr = [{
     label: '男',
     value: '0'
   }, {
     label: '女',
     value: '1'
   }]
   show() {
     this.newDialogVisible = true
   }
   activated() {
     if (!this.isFirst) {
       this.newDialogVisible = true
     }
   }
   deactivated() {
     this.isFirst = false
   }

   async submit() {
     const value = this.$validator(this.$refs.ValidateForm)
     if (value) return
     this.newDialogVisible = false
     const res = await custormSave(this.creatModel)
     if (res.success) {
       CommonMudule.SET_CUSTOMERID(res.data)
       PortMudule.SET_PLANID('')
       PortMudule.SET_RISKTYPE(null)
       this.$router.push('/system')
     } else {
       this.$message({
         message: res.errorMsg,
         type: 'error'
       })
     }
   }
}

