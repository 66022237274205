


















































































import { Vue, Component } from 'vue-property-decorator'
import { OptArr } from '@/types/kyc'
import { custormSearch } from '@/api/users'
import { userTable } from '@/types/user'
import { CommonMudule } from '@/store/modules/common'
import Pagination from '@/components/Pagination/index.vue'
import { PortMudule } from '@/store/modules/portfolio'
@Component({
  name: 'oldDaliog',
  components: {
    Pagination
  }
})
export default class extends Vue {
  noldDialogVisible = false
  alone = null
  private isFirst: Boolean = true
 private tableData:userTable[] =[]
  private listQuery = {
    page: 1,
    size: 5,
    customerName: ''
  }
   private totalSize = 0
   private listLoading = true
   private loading = false
   showDialog() {
     this.noldDialogVisible = true
   }
   deactivated() {
     this.isFirst = false
   }
   querySearchAsync() {

   }
   handleSelect() {
     this.noldDialogVisible = false
   }
   created() {
     this.init()
   }
   login(val:any) {
     CommonMudule.SET_CUSTOMERID(val)
     PortMudule.SET_PLANID('')
     PortMudule.SET_RISKTYPE(null)
     this.loading = true
     setTimeout(() => {
       this.loading = false
     }, 1000)
     this.$router.push('/system')
   }
   async init() {
     const param = {
       page: this.listQuery.page - 1,
       size: this.listQuery.size,
       customerName: this.listQuery.customerName
     }
     const res = await custormSearch(param)
     if (res.success) {
       this.tableData = res.data.content
       this.totalSize = res.data.totalElements
       setTimeout(() => {
         this.listLoading = false
       }, 0.5 * 1000)
     } else {
       this.$message({
         message: res.errorMsg,
         type: 'error'
       })
     }
   }
}

